import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import convertHtml from '../utils/convertHtml';
import SquiggleGenerator from '../components/squiggle';
import TitleUnderlined from '../components/TitleUnderlined';

const FlexibleCardsContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const FlexibleCardsContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 1440px;
  @media (max-width: 1350px) {
    width: 900px;
  }
  @media (max-width: 900px) {
    width: 840px;
  }
  @media (max-width: 840px) {
    width: 420px;
  }
  @media (max-width: 500px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    align-items: flex-start;
    justify-content: center;
  }
`;

const CardContainer = styled(motion.div)`
  padding: 15px;
  background-color: #4758eb;
  max-width: 310px;
  width: 100%;
  margin: 25px;
  flex-grow: 0 0 33.3%;
  z-index: 3;
  @media (max-width: 900px) {
    margin: 10px;
  }
  @media (max-width: 500px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  // &:nth-of-type(1) {
  //   margin-left: 0;
  // }

  // &:nth-of-type(3) {
  //   margin-left: 0;
  // }
`;

const Title = styled.div`
  font-family: DM Serif Text;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
`;

const Subtext = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #fff;
`;

const LeftSquiggle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const RightSquiggle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.75);
  margin-right: -10vw;
  @media (max-width: 900px) {
    display: none;
  }
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const clientItem = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

export class FlexibleCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <FlexibleCardsContainerOuter>
            <FlexibleCardsContainer
              variants={container}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <TitleContainer>
                <TitleUnderlined
                  titleText={'<span>' + this.props.block_title.text + '</span>'}
                  underlineColor={this.props.block_color_theme}
                  fontSize="40px"
                  tabletFontSize="40px"
                  tableLineHeight="48px"
                  mobileFontSize="36px"
                  mobileLineHeight="36px"
                  lineHeight="48px"
                />
              </TitleContainer>
              {this.props.items.map((item, index) => (
                <CardContainer
                  background={item.card_background_color}
                  key={index}
                  variants={clientItem}
                >
                  {item.step.text && (
                    <stepContainer>
                      <p
                        style={{
                          color: item.step_color,
                          fontSize: '12px',
                          letterSpacing: '0.15em',
                          fontWeight: 'bold',
                          marginBottom: '15px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {item.step.text}
                        <span
                          style={{
                            backgroundColor: item.step_color,
                            width: '20px',
                            height: '2px',
                            marginLeft: '10px',
                          }}
                        ></span>
                      </p>
                    </stepContainer>
                  )}
                  <Title
                    dangerouslySetInnerHTML={convertHtml(item.title.text)}
                  />
                  <Subtext
                    dangerouslySetInnerHTML={convertHtml(item.subtitle.text)}
                  />
                </CardContainer>
              ))}
            </FlexibleCardsContainer>
          </FlexibleCardsContainerOuter>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default FlexibleCards;
