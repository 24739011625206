import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import MeetOurTeamSplash from '../components/prismic-adapters/prismic-meet-our-team-splash';
import LazyImage from '../components/lazy-image';
import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';
import ButtonUnderlined from '../components/ButtonUnderlined';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';

const MeetOurTeamSplashContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const MeetOurTeamSplashContainer = styled.div`
  width: 90%;
  background-color: transparent;
  max-width: 1440px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
`;

const ImageContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const ImageContainer = styled.div`
  max-width: 80%;
  height: auto;
  width: 80%;
`;

const CtaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const Subtitle = styled.div`
  width: 90%;
  margin-left: 5%;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #404040;
  margin-bottom: 60px;
`;

export class homepageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <MeetOurTeamSplashContainerOuter>
            <MeetOurTeamSplashContainer>
              <TitleContainer>
                <TitleUnderlined
                  underlineColor={this.props.block_color_theme}
                  textColor={'#ffffff'}
                  fontSize={'58px'}
                  mobileFontSize={'26px'}
                  titleText={'<span>' + this.props.title_text.text + '</span>'}
                />
              </TitleContainer>
              <Subtitle
                dangerouslySetInnerHTML={convertHtml(
                  this.props.subtitle_text.text
                )}
              />
              <ImageContainerOuter>
                <ImageContainer>
                  {this.props.image && <LazyImage {...this.props.image} />}
                </ImageContainer>
              </ImageContainerOuter>
              <CtaContainer>
                <ButtonNotUnderlined
                  buttonText={this.props.cta_text.text}
                  link={this.props.cta_link.url}
                  color={this.props.cta_text_color}
                  arrowColor={this.props.cta_arrow_color}
                  target={this.props.cta_link.target}
                />
              </CtaContainer>
            </MeetOurTeamSplashContainer>
          </MeetOurTeamSplashContainerOuter>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default homepageHeader;
