import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import convertHtml from '../utils/convertHtml';

const StandardTextContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  h2 {
    font-family: 'DM Serif Text';
    margin-top: -10px;
    font-weight: 400;
    font-size: 36px;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 40px;
  }
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
  }
`;

const StandardTextInner = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
`;

export class StandardText extends Component {
  render() {
    return (
      <StandardTextContainer>
        <StandardTextInner>
          <h2>{this.props.title_text.text}</h2>
          <div
            dangerouslySetInnerHTML={convertHtml(this.props.text_content.html)}
          />
        </StandardTextInner>
      </StandardTextContainer>
    );
  }
}

export default StandardText;
