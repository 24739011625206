import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';
import Label from '../components/Label';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';

const VacanciesOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: #4758eb;
  overflow: hidden;
`;

const VacanciesContainer = styled.div`
  width: 90%;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 500px) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
  }
`;

const VacanciesContentOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VacanciesContentInner = styled.div`
  max-width: 715px;
`;

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 25px;
`;

const Subtitle = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin-bottom: 100px;
`;

const ParagraphContainer = styled.div``;

const ParagraphTitle = styled.div`
  font-family: DM Serif Text;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  color: #fff;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    font-size: 26px;
  }
`;

const ParagraphText = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #fff;
  margin-bottom: 100px;
  @media (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 50px;
  }
`;

const VacanciesItems = styled.div`
  color: #fff;

  button {
    div {
      color: #fff !important;
    }
    svg {
      fill: #fff !important;
    }
  }

  :nth-last-child(1) {
    border-bottom: 0px;
  }
  margin-bottom: 100px;
  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
`;

const VacancyItem = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  :nth-last-child(1) {
    border-bottom: 0px;
  }
`;

const Location = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const JobTitle = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: right;
  color: ${props => props.color};
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export class Vacancies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <VacanciesOuterContainer>
            <VacanciesContainer>
              <VacanciesContentOuter>
                <VacanciesContentInner>
                  <LabelContainer>
                    <Label
                      color={this.props.block_color_theme}
                      text={this.props.label_title.text}
                    />
                  </LabelContainer>
                  <TitleContainer>
                    <TitleUnderlined
                      titleText={'<span>' + this.props.title.text + '</span>'}
                      textColor={'#ffff'}
                      underlineColor={this.props.block_color_theme}
                      fontSize={'58px'}
                      mobileFontSize={'45px'}
                    />
                  </TitleContainer>
                  <Subtitle
                    dangerouslySetInnerHTML={convertHtml(
                      this.props.subtitle.text
                    )}
                  />
                  <ParagraphContainer>
                    <ParagraphTitle
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.paragraph_title.text
                      )}
                    />
                    <ParagraphText
                      dangerouslySetInnerHTML={convertHtml(
                        this.props.paragraph.text
                      )}
                    />
                  </ParagraphContainer>
                  <VacanciesItems>
                    {this.props.items.map((item, index) => (
                      <VacancyItem
                        href={item.job_link.url}
                        target={item.job_link.target}
                      >
                        <Location>{item.job_location.text}</Location>
                        <ButtonNotUnderlined
                          color="#FFF"
                          buttonText={item.job_title.text}
                        />
                      </VacancyItem>
                    ))}
                  </VacanciesItems>
                </VacanciesContentInner>
              </VacanciesContentOuter>
            </VacanciesContainer>
          </VacanciesOuterContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default Vacancies;
