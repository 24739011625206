import React, { Component } from 'react';
import styled from '@emotion/styled';
import convertHtml from '../../utils/convertHtml';
import convertId from '../../utils/convertId';

const BlogAnchorsBlockContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1150px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const BlogAnchorsBlockContainer = styled.div`
  width: 100%;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  color: #404040;

  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  a {
    cursor: pointer;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`;

const BlogAnchorsBlockContainerInner = styled.div`
  width: 70%;
  margin: 0 0 40px 40px;
  max-width: 800px;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;

const BlogAnchorsBlockList = styled.ul`
  list-style: none;
`;

const BlogAnchorsBlockHead = styled.h3`
  width: 100%;
  font-family: DM Serif Text;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  color: #404040;
`;

const AnchorItemContainer = styled.li`
  width: 100%;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #404040;
`;

const AnchorItem = styled.a`
  color: #4758eb;
`;

export class BlogAnchorsBlock extends Component {
  render() {
    let _headings = [];
    this.props.customData &&
      this.props.customData.body.map((item, index) => {
        if (item.slice_type == 'heading') {
          _headings.push(item.primary.title_text.text);
        }
      });
    let _items = [];
    if (this.props.items) {
      this.props.items.map(item => {
        if (item.anchor_link.url) {
          let _item = {};
          _item.title = item.anchor_title.text;
          _item.link = item.anchor_link.url
            .replace(/\s/g, '')
            .replace(/^\d+\.\s*/, '')
            .toLowerCase()
            .replace('https://anchor:', '')
            .replace('https://#', '');
          _items.push(_item);
        }
      });
    }

    return (
      <BlogAnchorsBlockContainerOuter>
        <BlogAnchorsBlockContainerInner>
          <BlogAnchorsBlockContainer>
            <BlogAnchorsBlockHead
              dangerouslySetInnerHTML={convertHtml(
                this.props.title.text
                  ? this.props.title.text
                  : "What you'll find in the article:"
              )}
            />
            <BlogAnchorsBlockList>
              {_headings &&
                _headings.map((item, index) => (
                  <AnchorItemContainer>
                    <AnchorItem key={index} href={'#' + convertId(item)}>
                      {item}
                    </AnchorItem>
                  </AnchorItemContainer>
                ))}
            </BlogAnchorsBlockList>
            <BlogAnchorsBlockList>
              {_items &&
                _items.map((item, index) => (
                  <AnchorItemContainer>
                    <AnchorItem key={index} href={item.link}>
                      {item.title}
                    </AnchorItem>
                  </AnchorItemContainer>
                ))}
            </BlogAnchorsBlockList>
          </BlogAnchorsBlockContainer>
        </BlogAnchorsBlockContainerInner>
      </BlogAnchorsBlockContainerOuter>
    );
  }
}

export default BlogAnchorsBlock;
