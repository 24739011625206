import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const WhatHappensNextContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5rem;
  box-sizing: border-box;
  position: relative;
  text-align: center !important;
  flex-wrap: wrap;
  @media (max-width: 965px) {
    margin: 0;
    padding: 1rem;
  }
  @media (max-width: 735px) {
    padding: 1rem;
  }
`;

export const WhatHappensCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
  padding: 1.5rem;
  margin: 0 1.5rem;
  box-sizing: border-box;
  width: 100%;
  @media screen and (max-width: 1380px) and (min-width: 1100px) {
    padding: 1rem;
    margin: 1rem;
  }
  @media (max-width: 965px) {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    min-height: 1px;

    border: 5px solid #fff;
    margin: 0px;
  }
  @media (max-width: 735px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const WhatHappensCardContent = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #404040;
  margin-top: 1rem;
`;
export const TextContent = styled.p`
  margin: 3rem 1rem;
  color: #404040;
  font-size: 18px;

  font-weight: 500;
  @media (max-width: 965px) {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
    margin: 1.5rem 0.5rem;
  }
  @media (max-width: 735px) {
  }
`;
