import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import LazyImage from '../../components/lazy-image';
import convertHtml from '../../utils/convertHtml';

const TeamMemberContainer = styled.div``;
const ImageContainer = styled.div`
  width: 320px;
  height: 270px;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 25px;
  position: relative;
  flex: 1 0 33.3%;
`;

const LowerDetails = styled.div`
  margin-left: 25px;
  margin-bottom: 25px;
`;

const Name = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #404040;
`;

const JobPosition = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-top: 10px;
`;

const BioContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Bio = styled.div`
  font-family: DM Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  margin-bottom: 20px;
`;

const Email = styled.div`
  font-family: DM Sans;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #404040;
  padding-bottom: 30px;
`;

const BioContentContainerOuter = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
`;

const container = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const BioContentContainerInner = styled.div``;

export class TeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  handleVisibilityChange = () => {
    if (this.state.visible === true) {
      this.setState({
        visible: false,
      });
    } else {
      this.setState({
        visible: true,
      });
    }
  };

  handleVisibilityChangeForced = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <TeamMemberContainer key={this.props.id}>
        <ImageContainer>
          <BioContainer
            variants={container}
            onTap={this.handleVisibilityChange}
            onHoverStart={this.handleVisibilityChange}
            onHoverEnd={this.handleVisibilityChangeForced}
            animate={this.state.visible ? 'visible' : 'hidden'}
          >
            <BioContentContainerOuter>
              <BioContentContainerInner>
                <Bio
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.data.client_bio.text
                  )}
                />
              </BioContentContainerInner>
            </BioContentContainerOuter>
          </BioContainer>
          {this.props.data.client_image && (
            <LazyImage {...this.props.data.client_image} />
          )}
        </ImageContainer>
        <LowerDetails>
          <Name
            dangerouslySetInnerHTML={convertHtml(
              this.props.data.client_name.text
            )}
          />
        </LowerDetails>
      </TeamMemberContainer>
    );
  }
}

export default TeamMember;
