import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../components/lazy-image';

const ClientListContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ClientListContainer = styled.div``;

const Title = styled.h2`
  font-family: DM Serif Text;
  font-style: normal;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  width: 100%;
  text-align: center;
  color: #404040;
  margin: 100px auto;
  max-width: 1080px;
  @media (max-width: 1200px) {
    max-width: 950px;
  }
  @media (max-width: 975px) {
    margin-top: 45px;
    margin-bottom: 45px;
    max-width: 800px;
  }
  @media (max-width: 850px) {
    max-width: 500px;
    font-size: 30px;
    line-height: 38px;
  }
  @media (max-width: 550px) {
    max-width: 300px;
    font-size: 30px;
    line-height: 38px;
  }
`;

const SubText = styled(motion.div)`
  color: #404040;
  margin-top: -70px;
  margin-bottom: 80px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;

  @media (max-width: 768px) {
    margin-top: -10px;
  }
`;

const Logo = styled(motion.div)`
  margin-left: 65px;
  margin-right: 65px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex: 0 0 15%;
  margin-bottom: 80px;
  @media (max-width: 975px) {
    margin-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 500px) {
    width: 120px;
    height: 25px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const LogoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  flex-wrap: wrap;
`;

const LogoImg = styled.img`
  object-fit: contain;
  height: 100%;
  width: 190px;
  @media (max-width: 500px) {
    width: 140px;
    height: 30px;
  }
  filter: grayscale(1) invert(1);
  transition-duration: 500ms;
  :hover {
    filter: invert(1);
    transform: scale(1.1);
    transition-duration: 500ms;
  }
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.0325,
    },
  },
};

const logoItem = {
  hidden: { y: 20, x: -10, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};

export class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <ClientListContainerOuter>
          <ClientListContainer>
            <Title>{this.props.title_text.text}</Title>
            {this.props.title_subtext.text && (
              <SubText>{this.props.title_subtext.text}</SubText>
            )}
            <LogoContainer
              variants={container}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              {this.props.items.map((item, index) => (
                <Logo key={index} variants={logoItem}>
                  <LogoImg src={item.client_logo.url} />
                </Logo>
              ))}
            </LogoContainer>
          </ClientListContainer>
        </ClientListContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default ClientList;
