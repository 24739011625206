import React from 'react';
import styled from '@emotion/styled';

//Component Import
import RoundCtaButton from '../components/roundCtaButton';
import BlankSpace from '../components/blankSpace';
import ButtonUnderlined from '../components/ButtonUnderlined';
import Label from '../components/Label';
import linkStripper from '../utils/link-stripper';

const CaseStudySplashContainer = styled.div`
  height: 150vh;
  display: flex;
  font-family: DM Sans;
  background-color: ${props => props.page_background_color};
  overflow: hidden;
  @media (max-width: 1020px) {
    height: fit-content;
  }
`;

const ContentContainer = styled.div``;

const LabelContainerOuter = styled.div`
  position: absolute;
  right: 0;
  display: none;
`;

const LabelContainerInner = styled.div``;

const TextContainer = styled.div`
  max-width: 800px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 1400px;
  @media (max-width: 1020px) {
    height: 700px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const TextContainerInner = styled.div`
  @media (max-width: 1020px) {
    margin-top: 75px;
  }
`;

const TagContainer = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #817e7e;
`;

const Title = styled.h1`
  color: ${props => props.title_text_color};
  font-weight: 400;
  font-size: 80px;
  font-style: normal;
  font-family: DM Serif Text;
  line-height: 80px;
  margin-top: 15px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  max-width: 450px;
  margin-top: 25px;
`;

const CtaTitle = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #404040;
`;

const ParagraphContainer = styled.div`
  width: 500px;
  margin-left: 150px;
  @media (max-width: 1020px) {
    margin-left: 0px;
    width: fit-content;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
  }
`;

const ParagraphTitle = styled.div`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  font-family: DM Sans;
  font-style: normal;
  margin-bottom: 30px;
  color: #404040;
`;

const ParagraphText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 30px;
  color: #404040;
`;

const ImageContainer = styled.div`
  min-width: 600px;
  position: relative;
  height: 100%;
  @media (max-width: 1300px) {
    min-width: 300px;
  }

  @media (max-width: 1020px) {
    min-width: 100px;
  }

  @media (max-width: 1020px) {
    min-width: 100px;
    display: none;
  }
`;

const SplashImg = styled.img`
  width: 500px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const CaseStudySplash = ({
  props,
  page_background_color,
  tags,
  title,
  title_text_color,
  subtitle,
  subtitle_text_color,
  cta_text,
  cta_link,
  cta_link_target,
  label_text,
  label_color,
  label_text_color,
  header_image,
  paragraph_title,
  paragraph_text,
  paragraph_title_color,
  paragraph_cta_text,
  paragraph_cta_link,
  paragraph_cta_color,
  paragraph_cta_link_target,
}) => (
  <CaseStudySplashContainer page_background_color={page_background_color}>
    <ImageContainer>
      <LabelContainerOuter>
        <LabelContainerInner>
          <Label color={label_color} text={label_text} />
        </LabelContainerInner>
      </LabelContainerOuter>
      <SplashImg src={header_image} />
    </ImageContainer>
    <ContentContainer>
      <TextContainer>
        <TextContainerInner>
          <TagContainer>{tags}</TagContainer>
          <Title title_text_color={title_text_color}>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <BlankSpace height="25px" />
          <RoundCtaButton
            target={cta_link_target}
            text={cta_text}
            link={linkStripper(cta_link)}
          />
        </TextContainerInner>
      </TextContainer>

      <ParagraphContainer>
        <ParagraphTitle paragraph_title_color={paragraph_title_color}>
          {paragraph_title}
        </ParagraphTitle>
        <ParagraphText subtitle_text_color={subtitle_text_color}>
          {paragraph_text}
        </ParagraphText>
        <ButtonUnderlined
          color={paragraph_cta_color}
          buttonText={paragraph_cta_text}
          link={linkStripper(paragraph_cta_link)}
          target={paragraph_cta_link_target}
        />
      </ParagraphContainer>
    </ContentContainer>
  </CaseStudySplashContainer>
);

export default CaseStudySplash;
