import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ScoreContainer = styled(motion.div)`
  display: flex;
`;
export const StarContainer = styled(motion.div)``;

export const SharedStarDiv = styled.div`
  max-width: 810px;
  margin: 0 auto;
  padding: 0 1rem 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SharedStarText = styled.div`
  color: #404040;
  font-size: 18px;
  font-weight: 500;
  margin: 3rem 0 4rem;
  font-family: 'DM Sans';

  @media (max-width: 1480px) {
    font-size: 17px;
    margin: 2rem 0 2.5rem;
  }
  @media (max-width: 965px) {
    font-size: 14px;
    margin: 1rem 0 1.5rem;
  }
  @media (max-width: 735px) {
  }
`;
export const SliderSlickDiv = styled.div`
  overflow: hidden;
  .center-slide {
    .single-slider {
      & > .slick-slide {
        width: 100% !important;
      }
    }
    .slick-slide {
      p {
        // color: #404040;
        padding: 24px 10px 24px 10px;
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          padding: 44px;
        }

        @media screen and (min-width: 64em) {
          padding: 10px 87px;
        }

        // @media (min-width: 481px) {
        color: #ffffff;
        // }
      }
      * {
        &:focus {
          outline: none !important;
        }
      }
      div[class*='SliderItem'] {
        max-width: calc(100% - 120px);
        padding: 0px 60px;

        @media (max-width: 1024px) {
          max-width: calc(100% - 60px);
          padding: 0px 30px;
        }
      }

      @media (max-width: 480px) {
        div[class*='SliderItem'] {
          max-width: calc(100% - 40px);
          padding: 0px 20px;
        }
      }
    }
    @media (max-width: 480px) {
      .slick-list {
        padding: 0 155% 0 0 !important;
      }
    }
    .slick-center.slick-active {
      p {
        color: #ffffff;
      }
    }
  }
`;
export const SliderItem = styled.div``;

export const SliderOneItem = styled.div`
  padding-left: 23%;
  padding-right: 23%;
`;

export const SliderItemText = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 30px;

  @media (max-width: 1439px) {
    font-size: 23px;
  }

  @media (max-width: 1199px) {
    font-size: 22px;
  }

  @media (max-width: 965px) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 735px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const SliderItemName = styled.p`
  margin: 0;
  color: #fff !important;
  font-size: 15px;
  line-height: 1.5;
  font-style: normal;

  @media (max-width: 1199px) {
    font-size: 12px;
  }
`;

export const LabelContainer = styled.div`
  width: 100%;
  margin: 35px 0 24px;
  text-align: center;
`;

export const StarDivWithBackground = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  background-color: transparent;
  box-shadow: 0px 16px 25px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 15px 18px;
  display: flex;
  align-items: center;
  align-content: center;

  svg {
    margin: 0.5rem;
    width: 74px;
    height: 71px;
  }
  @media (max-width: 1480px) {
    margin-bottom: 1rem;

    svg {
      width: 38px;
      height: 38px;
    }
  }
  @media (max-width: 965px) {
    margin-bottom: 0.5rem;
    padding: 12px 14px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: 735px) {
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
