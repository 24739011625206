import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Helmet from 'react-helmet';

//Component Import
import FaqItem from '../components/faqItem';
import convertHtml from '../utils/convertHtml';

const FaqContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  overflow: hidden;
  @media (max-width: 1000px) {
    margin-bottom: 50px;
  }
`;

const FaqContainerInner = styled.div`
  max-width: 1150px;
  @media (max-width: 1325px) {
    width: 90%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const FaqTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  font-family: 'DM Serif Text';
  color: #404040;

  b,
  strong {
    font-weight: 800;
  }

  span {
    color: ${props => props.accent};
  }
`;

const FaqItemsContainer = styled(motion.div)``;

const FaqItems = [
  {
    question: 'Is a SEO migration project really necessary?',
    answer: 'this is an answer',
  },
  {
    question: "We've never done any SEO - is SEO migration still important?",
    answer: 'this is an answer',
  },
  {
    question: 'How much does SEO migration cost?',
    answer: 'this is an answer',
  },
  {
    question:
      'Will SEO migration guarantee more organic traffic to my website?',
    answer: 'this is an answer',
  },
  {
    question: 'Will I lose my rankings?',
    answer:
      'Our job is to ensure this doesn’t happen (or at least mitigate this as much as possible). That said the very nature of the project, the proposed changes and wider business decisions that go into the end product may have negative consequences*. We typically carry out a risk assessment which predicts certain outcomes and outlines resolutions to potential issues before they arise, with the ultimate outcome being to safeguard your brand as much as possible.',
  },
  {
    question: 'How will I know if the website migration has worked properly?',
    answer: 'this is an answer',
  },
];

const list = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const itemsFaq = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
};

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const faqItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const ContainerDiv = styled(motion.ul)`
  width: 150px;
  height: 150px;
  display: grid;
  overflow: hidden;
  margin: 0;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
`;

const ItemDiv = styled(motion.li)`
  background: red;
  border-radius: 100%;
`;

const FaqItemContainerMaster = styled(motion.div)``;

export class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <Helmet>
            <script type="application/ld+json">{`
              {"@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                  ${this.props.items.map(
                    (faqItem, index) => `@type": "Question",
                  "name": "${faqItem.question.text}",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "${faqItem.answer.html}")
                  }`
                  )}
                ]
              }
            `}</script>
          </Helmet>
          <FaqContainer>
            <FaqContainerInner>
              <FaqTitle
                accent={this.props.block_color_theme}
                dangerouslySetInnerHTML={convertHtml(this.props.title)}
              />
              <FaqItemsContainer
                variants={container}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.items.map((faqItem, index) => (
                  <FaqItemContainerMaster key={index} variants={faqItem}>
                    <FaqItem
                      question={faqItem.question}
                      answer={faqItem.answer}
                      block_color_theme={this.props.block_color_theme}
                    />
                  </FaqItemContainerMaster>
                ))}
              </FaqItemsContainer>
            </FaqContainerInner>
          </FaqContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default Faq;
