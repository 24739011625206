import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import convertHtml from '../utils/convertHtml';

//Asset import
import Arrow from '../images/assets/faqArrow.svg';

const FaqItemContainer = styled.div`
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 0px;
  border-bottom-width: 1px;
  align-items: center;
  flex-wrap: wrap;
`;

const FaqItemContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const FaqAnswerContainer = styled(motion.div)`
  width: 100%;
  overflow: hidden;
`;

const Question = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  font-family: 'DM Serif Text';
  color: #404040;
  padding-right: 20px;
  cursor: pointer;
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

const ArrowContainer = styled.div`
  cursor: 'pointer';
`;

const FaqAnswer = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  font-family: DM Sans;
  color: #404040;

  li {
    list-style-type: initial;
    margin-left: 20px;
  }
  p,
  strong,
  b {
    font-weight: normal;
  }
  em {
    font-style: normal;
  }
  a {
    color: #4758eb;
  }
  @media (max-width: 750px) {
    font-size: 16px;
  }
`;

const answerAnim = {
  hidden: { height: '0px' },
  visible: {
    height: 'fit-content',
  },
};

export class faqItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  itemClickedToggle = props => {
    if (this.state.active) {
      this.setState({
        active: false,
      });
    } else {
      this.setState({
        active: true,
      });
    }
  };

  render() {
    //console.log('hereisfaq', this.props);
    return (
      <FaqItemContainer>
        <FaqItemContainerInner onClick={() => this.itemClickedToggle()}>
          <Question>{this.props.question.text}</Question>
          {this.state.active ? (
            <ArrowContainer
              css={{
                transform: 'rotate(90deg)',
              }}
            >
              <Arrow fill={this.props.block_color_theme} />
            </ArrowContainer>
          ) : (
            <ArrowContainer>
              <Arrow fill={this.props.block_color_theme} />
            </ArrowContainer>
          )}
        </FaqItemContainerInner>
        <FaqAnswerContainer
          variants={answerAnim}
          initial="hidden"
          animate={this.state.active ? 'visible' : 'hidden'}
        >
          <FaqAnswer
            dangerouslySetInnerHTML={convertHtml(
              this.props.answer &&
                (this.props.answer.html || this.props.answer.text)
            )}
          ></FaqAnswer>
        </FaqAnswerContainer>
      </FaqItemContainer>
    );
  }
}

export default faqItem;
