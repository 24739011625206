import React from 'react';
import Arrow from './assets/arrow.svg';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import linkStripper from '../../utils/link-stripper';
import { Link } from 'gatsby';

const ButtonUnderlinedContainer = styled(motion.div)`
  display: flex;
  border-width: 0px;
  border-style: solid;
  border-color: ${props => props.color};
  // border-bottom-width: 2px;
  width: fit-content;
  padding-bottom: 7.5px;
  margin: ${props => props.margin};
  cursor: pointer;
  font-family: DM Sans;
`;

const ButtonText = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #404040;
`;

const ArrowContainer = styled(motion.div)`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

const ButtonUnderlinedContainerInner = styled.div`
  display: flex;
`;

const ButtonUnderlinedWArrow = props => {
  const [active, setActive] = React.useState(false);
  return (
    <ButtonUnderlinedContainer
      color="#404040"
      margin={props.margin}
      onHoverStart={() => setActive(!active)}
      onHoverEnd={() => setActive(!active)}
    >
      {props.gatsbyMode && props.gatsbyMode === true ? (
        <Link to={props.link}>
          <ButtonUnderlinedContainerInner>
            <ButtonText color="#404040">{props.buttonText}</ButtonText>
            <ArrowContainer
              color="#404040"
              animate={active ? { translateX: '5px' } : { translateX: '0px' }}
              transition={{ duration: 0.5 }}
            >
              <Arrow style={{ fill: '#404040' }} />
            </ArrowContainer>
          </ButtonUnderlinedContainerInner>
        </Link>
      ) : (
        <a href={props.link} target={props.target}>
          <ButtonUnderlinedContainerInner>
            <ButtonText color="#404040">{props.buttonText}</ButtonText>
            <ArrowContainer
              color="#404040"
              animate={active ? { translateX: '5px' } : { translateX: '0px' }}
              transition={{ duration: 0.5 }}
            >
              <Arrow style={{ fill: '#404040' }} />
            </ArrowContainer>
          </ButtonUnderlinedContainerInner>
        </a>
      )}
    </ButtonUnderlinedContainer>
  );
};

export default ButtonUnderlinedWArrow;
