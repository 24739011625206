import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../../components/lazy-image';
import TitleUnderlined from '../../components/TitleUnderlined';
import {
  GrowthTeamSection,
  OurGrowthTeamContainer,
  TeamMembersWrap,
  GrowthTeamSectionMedia,
  MemberName,
  MemberEmail,
  TextContent,
} from './style';

export class OurGrowthTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    const {
      title_text,
      subtitle_text,
      team_member_one_image,
      team_member_one_name,
      team_member_one_email,
      team_member_two_image,
      team_member_two_name,
      team_member_two_email,
    } = this.props;
    const { visibilitySensorActive } = this.state;
    return (
      <VisibilitySensor
        active={visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <OurGrowthTeamContainer>
          <TitleUnderlined
            titleText={`<span> ${title_text.text || `Our growth team`} </span>`}
            underlineColor="#4758eb"
            fontSize="58px"
            tabletFontSize="46px"
            tableLineHeight="46px"
            mobileFontSize="36px"
            mobileLineHeight="36px"
            lineHeight="40px"
            h2Mode={true}
          />
          <TextContent>{subtitle_text.text || 'Lorem Ipsum'}</TextContent>
          <TeamMembersWrap>
            <GrowthTeamSection>
              <GrowthTeamSectionMedia>
                {team_member_one_image && (
                  <LazyImage {...team_member_one_image} />
                )}
              </GrowthTeamSectionMedia>
              <MemberName>{team_member_one_name.text || ''}</MemberName>
              <MemberEmail>
                <a href={'mailto:' + team_member_one_email.text}>
                  {team_member_one_email.text || ''}
                </a>
              </MemberEmail>
            </GrowthTeamSection>
            <GrowthTeamSection>
              <GrowthTeamSectionMedia>
                {team_member_two_image && (
                  <LazyImage {...team_member_two_image} />
                )}
              </GrowthTeamSectionMedia>
              <MemberName>{team_member_two_name.text || ''}</MemberName>
              <MemberEmail>
                <a href={'mailto:' + team_member_two_email.text}>
                  {team_member_two_email.text || ''}
                </a>
              </MemberEmail>
            </GrowthTeamSection>
          </TeamMembersWrap>
          {/* <div>
            Title: {this.props.title_text.text}
            Underlined Color: {this.props.title_underline_color}
          </div>
          <div>Subtitle: {this.props.subtitle_text.text}</div>
          <div>
            Team Member One Image:
            <img src={this.props.team_member_one_image.url} />
          </div>
          <div>
            Team Member One Name: {this.props.team_member_one_name.text}
          </div>
          <div>
            Team Member One Email: {this.props.team_member_one_email.text}
          </div>
          <div>
            Team Member Two Image:
            <img src={this.props.team_member_two_image.url} />
          </div>
          <div>
            Team Member Two Name: {this.props.team_member_two_name.text}
          </div>
          <div>
            Team Member Two Email: {this.props.team_member_two_email.text}
          </div> */}
        </OurGrowthTeamContainer>
      </VisibilitySensor>
    );
  }
}

export default OurGrowthTeam;
