import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

import Carousel from '../components/carousel';

//import Carousel from '../components/carousel';

//Component Import
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';

const CaseStudySliderContainer = styled(motion.div)`
  background-color: ${props => props.backgroundColor};
  background-color: #848ee0;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
  }

  a {
    div {
      color: #fff !important;
    }
    svg {
      fill: #fff !important;
    }
  }
`;

const BlockTitle = styled(motion.h2)`
  color: #fff;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  font-style: normal;
  font-family: 'DM Serif Text';

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

const HeaderContainerOuter = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  @media (max-width: 1250px) {
    width: 90%;
  }
`;

const SliderContainer = styled(motion.div)`
  width: 100%;
  cursor: grab;
  margin-left: 10%;
  @media (max-width: 600px) {
    margin-left: 5%;
  }
`;

const HeaderTextContent = styled(motion.div)``;

export class CaseStudiesSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <CaseStudySliderContainer
            backgroundColor={this.props.block_background_color}
          >
            <HeaderContainerOuter>
              <HeaderContainer>
                <HeaderTextContent>
                  <Label color="#fff" text={this.props.label_title.text} />
                  <BlankSpace height={'20px'} />
                  <BlockTitle color={this.props.text_color}>
                    {this.props.title_text.text}
                  </BlockTitle>
                </HeaderTextContent>
              </HeaderContainer>
            </HeaderContainerOuter>
            <SliderContainer>
              <Carousel
                items={this.props.items}
                color={this.props.accent_color}
                visible={this.state.visible}
              />
            </SliderContainer>

            {/*
            <SliderContainer>
              <Carousel infinite slidesPerPage={3}>
                <img src="http://react-responsive-carousel.js.org/assets/5.jpeg" />
                <img src="http://react-responsive-carousel.js.org/assets/5.jpeg" />
              </Carousel>
            </SliderContainer>
            */}
          </CaseStudySliderContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default CaseStudiesSlider;
