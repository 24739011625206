import React, { Component } from 'react';
import styled from '@emotion/styled';
import convertHtml from '../../utils/convertHtml';
import convertId from '../../utils/convertId';

const BlogHeadingContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 1080px;
  margin: auto;
`;

const BlogHeadingContainer = styled.h3`
  width: 100%;
  // max-width: 800px;
  font-family: DM Serif Text;
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  color: #404040;
  margin-bottom: ${props => props.marginBottom};
  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    font-size: ${props => props.mobileSize};
    line-height: ${props => props.mobileLineHeight};
    margin-bottom: ${props => props.marginBottomMobile};
  }
`;
export class BlogHeading extends Component {
  render() {
    let fontSize;
    let lineHeight;
    let mobileSize;
    let mobileLineHight;
    let marginBottom;
    let marginBottomMobile;
    if (this.props.heading_size === 'Heading One') {
      fontSize = '28px';
      lineHeight = '36px';
      mobileSize = '34px';
      mobileLineHight = '44px';
      marginBottom = '15px';
      marginBottomMobile = '15px';
    } else if (this.props.heading_size === 'Heading Two') {
      fontSize = '24px';
      lineHeight = '30px';
      mobileSize = '24px';
      mobileLineHight = '28px';
      marginBottom = '10px';
      marginBottomMobile = '10px';
    } else if (this.props.heading_size === 'Heading Three') {
      fontSize = '18px';
      lineHeight = '26px';
      mobileSize = '24px';
      mobileLineHight = '34px';
      marginBottom = '10px';
      marginBottomMobile = '10px';
    } else {
      fontSize = '28px';
      lineHeight = '36px';
      mobileSize = '18px';
      mobileLineHight = '21px';
      marginBottom = '10px';
      marginBottomMobile = '10px';
    }
    return (
      <BlogHeadingContainerOuter>
        <BlogHeadingContainer
          dangerouslySetInnerHTML={convertHtml(this.props.title_text)}
          color={
            this.props.color && this.props.color !== null
              ? this.props.heading_color
              : '#ffffff'
          }
          fontSize={fontSize}
          mobileSize={mobileSize}
          lineHeight={lineHeight}
          mobileLineHeight={mobileLineHight}
          marginBottom={marginBottom}
          id={convertId(this.props.title_text)}
        />
      </BlogHeadingContainerOuter>
    );
  }
}

export default BlogHeading;
