import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../components/lazy-image';
import LinkStripper from '../utils/link-stripper';
import UrlParser from '../utils/urlParser';
import TitleUnderlined from '../components/TitleUnderlined';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';

const RightTwoTextLeftImageContainerOuter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
  overflow: hidden;
  @media (max-width: 650px) {
    margin-bottom: 50px;
  }
`;

const RightTwoTextLeftImageContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 1050px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const ImageOne = styled(motion.div)`
  height: 475px;
  width: 365px;
  @media (max-width: 650px) {
    height: 330px;
    width: 275px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 50px;
  @media (max-width: 1050px) {
    width: 100%;
    justify-content: center;
    margin-top: 75px;
  }
  @media (max-width: 650px) {
    margin-top: 45px;
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: space-evenly;
  align-items: center;
  @media (max-width: 1050px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

const ParagraphOne = styled(motion.div)`
  width: 50%;
  margin-right: 50px;
  margin-left: 50px;
  @media (max-width: 800px) {
    margin-right: 25px;
    margin-left: 25px;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const ParagraphTwo = styled(motion.div)`
  width: 50%;
  margin-left: 50px;
  padding-right: 25px;
  @media (min-width: 1500px) {
    padding-right: 0px;
  }
  @media (max-width: 800px) {
    margin-right: 25px;
    margin-left: 25px;
    padding-right: 0px;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

const Paragraph = styled.div`
  font-family: DM Sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-top: 20px;
  margin-bottom: 25px;
`;

const ImageAnim = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.35,
    },
  },
};

const TextOneAnim = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.1,
      duration: 0.35,
    },
  },
};

const TextTwoAnim = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      duration: 0.35,
    },
  },
};

export class RightTwoTextLeftImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <RightTwoTextLeftImageContainerOuter>
          <RightTwoTextLeftImageContainer>
            <LeftContent>
              <ImageOne
                variants={ImageAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.image && <LazyImage {...this.props.image} />}
              </ImageOne>
            </LeftContent>
            <RightContent>
              <ParagraphOne
                variants={TextOneAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <TitleUnderlined
                  leftAligned={true}
                  textColor={'#ffffff'}
                  fontSize={'24px'}
                  titleText={this.props.text_block_1_title.text}
                />
                <Paragraph>{this.props.text_block_1_paragraph.text}</Paragraph>
                <ButtonNotUnderlined
                  color={this.props.block_color_theme}
                  arrowColor={this.props.block_color_theme}
                  buttonText={this.props.text_block_1_cta_text.text}
                  link={LinkStripper(
                    UrlParser(this.props.text_block_1_cta_link)
                  )}
                />
              </ParagraphOne>
              <ParagraphTwo
                variants={TextTwoAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <TitleUnderlined
                  leftAligned={true}
                  textColor={'#ffffff'}
                  fontSize={'24px'}
                  titleText={this.props.text_block_2_title.text}
                />
                <Paragraph>{this.props.text_block_2_paragraph.text}</Paragraph>
                <ButtonNotUnderlined
                  color={this.props.block_color_theme}
                  arrowColor={this.props.block_color_theme}
                  buttonText={this.props.text_block_2_cta_text.text}
                  link={LinkStripper(
                    UrlParser(this.props.text_block_2_cta_link)
                  )}
                />
              </ParagraphTwo>
            </RightContent>
          </RightTwoTextLeftImageContainer>
        </RightTwoTextLeftImageContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default RightTwoTextLeftImage;
