import React, { Component, useReducer, useEffect } from 'react';
import emailjs from 'emailjs-com';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';
import convertHtml from '../../utils/convertHtml';
import TitleUnderlined from '../../components/TitleUnderlined';
import scrollToView from '../../components/scrollToView';
import RightArrow from './RightArrow';
import Button from '../../components/Button';
import { navigate } from 'gatsby';
import {
  RenderTextAreaField,
  RenderSelect,
  RenderTextField,
} from '../../components/InputFields';
import {
  fields,
  errorMessage,
  networkErrorMessage,
  successMessage,
  phoneRegex,
  emailRegex,
} from './constants';
import reducer, { initialState } from './stateReducer';
import emailTemplate from './emailTemplate';
import {
  FormWraper,
  FormGroup,
  HalfCol,
  FullCol,
  ContactFormContainer,
  GetInTouchSection,
  AlternateEmailSection,
  ErrorUI,
  SectionContainer,
} from './style';

const ParagraphTwo = styled.div`
  strong {
    font-weight: bold;
  }
`;

export const ContactForm = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isnameInvalid,
    isnameTouched,
    isphoneInvalid,
    isphoneTouched,
    isemailInvalid,
    isemailTouched,
    isreasonInvalid,
    isreasonTouched,
    ismessageInvalid,
    ismessageTouched,
    disabled,
    phone,
    email,
    name,
    reason,
    message,
    error,
    isSuccess,
    isLoading,
    active,
    visible,
    visibilitySensorActive,
  } = state;

  useEffect(() => {
    if (isSuccess || error !== '') {
      setTimeout(() => {
        setResponseStatus({ isSucces: false, error: '' });
      }, 2000);
    }
  }, [isSuccess, error]);
  const contactReasonOptions = props.reason_for_contacting_options.text
    .split(',')
    .map(v => v.trim());

  let clientEmails = [];
  if (props.items) {
    props.items &&
      props.items.filter(item => {
        if (item.target_email.text) {
          clientEmails.push(item.target_email.text);
        }
      });
  }

  let optionData = [];
  if (contactReasonOptions) {
    contactReasonOptions.filter(item => {
      optionData.push({ value: item, label: item });
    });
  }

  let selectChanged = false;

  const setFormValues = e => {
    let value = '';
    selectChanged = true;
    e.preventDefault();
    dispatch({
      type: 'setFormValue',
      fieldName: e.target.name,
      value: e.target.value,
    });
    switch (e.target.name) {
      case 'phone':
        value = phoneRegex.test(e.target.value);
        break;
      case 'email':
        value = emailRegex.test(e.target.value);
        break;
      default:
        value = e.target.value.length > 0;
        break;
    }
    dispatch({
      type: 'setFormValidation',
      name: e.target.name,
      value,
      disabled: false,
    });
  };
  const handleOnBlur = e => {
    dispatch({
      type: 'setFieldStatus',
      name: e.target.name,
      value: true,
    });
  };

  const setResponseStatus = status => {
    dispatch({
      type: 'setSendMessageResponse',
      ...status,
    });
  };

  const setMessageLoading = value => {
    dispatch({
      type: 'sendMessageLoading',
      value,
    });
  };
  const handleFormSubmit = () => {
    const {
      isnameInvalid,
      isemailInvalid,
      isphoneInvalid,
      ismessageInvalid,
      isreasonInvalid,
      isnameTouched,
      isemailTouched,
      isphoneTouched,
      isreasonTouched,
      ismessageTouched,
    } = state;
    if (
      isnameTouched &&
      !isnameInvalid &&
      isemailTouched &&
      !isemailInvalid &&
      isphoneTouched &&
      !isphoneInvalid &&
      isreasonTouched &&
      !isreasonInvalid &&
      ismessageTouched &&
      !ismessageInvalid
    ) {
      const template = emailTemplate({ state, clientEmails });
      setMessageLoading(true);
      emailjs
        .send(
          process.env.GATSBY_service_id,
          process.env.GATSBY_template_id,
          template,
          process.env.GATSBY_user_id
        )
        .then(
          () => {
            setResponseStatus({ isSuccess: true });
            setMessageLoading(false);
            scrollToView({ id: 'messageContainer' });
            navigate('/thank-you/');
          },
          error => {
            setResponseStatus({
              error: error.status === 400 ? errorMessage : networkErrorMessage,
            });
            setMessageLoading(false);
            scrollToView({ id: 'messageContainer' });
          }
        );
    } else {
      fields.map(item => {
        if (!state[`is${item}Touched`]) {
          handleOnBlur({ target: { name: item } });
        }
      });
    }
  };
  const { title, paragraph_one, paragraph_two, accent_color } = props;
  const handleVisibilityChange = visibleData => {
    if (visible !== visibleData) {
      dispatch({
        type: 'handleVisibilityChange',
        visible: visibleData,
        visibilitySensorActive: !visibleData,
      });
    }
  };

  const css = `
    #messageContainer label span {
      color: #404040!important;
    }
    textarea {
      height: 200px!important;
      resize: none;
    }
  `;

  const ButtonStyles = styled.div`
    button {
      border-color: #404040;
      background-color: #404040;
      color: #212121;
      min-width: unset !important;
      padding-right: 30px !important;
      @media (max-width: 965px) {
        padding: 0 20px;
        font-size: 20px;
      }

      svg {
        fill: #212121;
      }
    }
    button:hover,
    button:hover {
      background-color: #404040;
      color: #212121;

      svg {
        left: 15px;
        fill: #212121;
      }
    }
  `;

  return (
    <VisibilitySensor
      active={visibilitySensorActive}
      onChange={handleVisibilityChange}
      partialVisibility
      minTopValue={250}
    >
      <ContactFormContainer id="messageContainer">
        <style>{css}</style>
        <SectionContainer>
          <GetInTouchSection>
            <TitleUnderlined
              titleText={`<span> ${(title && title.text) ||
                `Get In Touch`} </span>`}
              fontSize="40px"
              tabletFontSize="36px"
              tableLineHeight="36px"
              mobileFontSize="36px"
              mobileLineHeight="36px"
              lineHeight="48px"
              margin="0 auto"
            />
            <FormWraper>
              <HalfCol>
                <AlternateEmailSection
                  dangerouslySetInnerHTML={convertHtml(paragraph_one.text)}
                />
              </HalfCol>
              <HalfCol>
                <AlternateEmailSection className="dark">
                  <ParagraphTwo
                    dangerouslySetInnerHTML={convertHtml(paragraph_two.text)}
                  />
                </AlternateEmailSection>
              </HalfCol>
            </FormWraper>
          </GetInTouchSection>
          <FormWraper>
            <ErrorUI isSuccess={isSuccess}>
              {isSuccess && successMessage}
              {error}
            </ErrorUI>
            <HalfCol>
              <FormGroup>
                <RenderTextField
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  autocomplete="new-password"
                  errorMessage="Name is required"
                  isInvalid={isnameTouched && isnameInvalid}
                  value={name}
                  onChange={setFormValues}
                  onBlur={handleOnBlur}
                />
              </FormGroup>
            </HalfCol>
            <HalfCol>
              <FormGroup>
                <RenderTextField
                  type="text"
                  name="phone"
                  value={phone}
                  autocomplete="new-password"
                  placeholder="Your Phone Number"
                  errorMessage={`Phone number is ${
                    phone.length > 0 ? 'invalid' : 'required'
                  }`}
                  isInvalid={isphoneTouched && isphoneInvalid}
                  onChange={setFormValues}
                  onBlur={handleOnBlur}
                />
              </FormGroup>
            </HalfCol>
            <FullCol>
              <FormGroup>
                <RenderTextField
                  type="email"
                  name="email"
                  autocomplete="new-password"
                  value={email}
                  placeholder="Your Email Address"
                  errorMessage={`Email is ${
                    email.length > 0 ? 'invalid' : 'required'
                  }`}
                  isInvalid={isemailTouched && isemailInvalid}
                  onChange={setFormValues}
                  onBlur={handleOnBlur}
                />
              </FormGroup>
            </FullCol>
            <FullCol>
              <FormGroup>
                <RenderSelect
                  name="reason"
                  options={optionData}
                  value={''}
                  errorMessage="Reason is required"
                  selectedValue={reason}
                  isInvalid={isreasonTouched && isreasonInvalid}
                  defaultValue="Reason For Contacting"
                  onChange={setFormValues}
                  onBlur={handleOnBlur}
                />
              </FormGroup>
            </FullCol>
            <FullCol>
              <FormGroup>
                <RenderTextAreaField
                  rows={5}
                  name="message"
                  value={message}
                  placeholder={'Your Message'}
                  errorMessage="Message is required"
                  isInvalid={ismessageTouched && ismessageInvalid}
                  onChange={setFormValues}
                  onBlur={handleOnBlur}
                />
              </FormGroup>
            </FullCol>
            <FullCol className="right">
              <ButtonStyles>
                <Button
                  type="button"
                  isLoading={isLoading}
                  disabled={disabled}
                  onClick={handleFormSubmit}
                >
                  <span>Send Message</span>
                  <RightArrow />
                </Button>
              </ButtonStyles>
            </FullCol>
          </FormWraper>
        </SectionContainer>
      </ContactFormContainer>
    </VisibilitySensor>
  );
};

export default ContactForm;
