import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import linkStripper from '../utils/link-stripper';

import { motion } from 'framer-motion';

//Asset Import
import DownArrow from '../images/assets/downArrow.svg';

const RoundCtaButtonContainerOuter = styled.div`
  padding-top: 5px;
`;

const RoundCtaButtonContainer = styled(motion.div)`
  display: flex;
  justify-conent: center;
  align-items: center;
  padding-top: 0px;
`;

const CtaText = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #404040 !important;
  margin-right: 15px;
`;

const Circle = styled(motion.div)`
  border: 1px solid #cacaca;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const DownArrowContainer = styled(motion.div)`
  margin-top: -2px;
`;

const RoundCtaButton = ({ text, link, target }) => {
  const [active, setActive] = React.useState(false);
  return (
    <a href={linkStripper(link)} target={target}>
      <RoundCtaButtonContainerOuter>
        <RoundCtaButtonContainer
          whileHover={{ translateX: '5px' }}
          onHoverStart={() => setActive(!active)}
          onHoverEnd={() => setActive(!active)}
        >
          <CtaText>{text}</CtaText>
          <Circle
            animate={active ? { translateX: '10px' } : { translateX: '0px' }}
            transition={{ duration: 0.5 }}
          >
            <DownArrowContainer>
              <DownArrow />
            </DownArrowContainer>
          </Circle>
        </RoundCtaButtonContainer>
      </RoundCtaButtonContainerOuter>
    </a>
  );
};

export default RoundCtaButton;
