import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

import SimpleCarousel from '../components/simpleCarousel';

const ImageGalleryContainer = styled(motion.div)`
  background-color: ${props => props.backgroundColor};
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  overflow: hidden;
  background-color: transparent !important;
  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const BlockTitle = styled(motion.h2)`
  // color: ${props => props.title_color};
  color: #404040;
  font-weight: 800;
  font-size: 40px;
  line-height: 59px;
  font-style: normal;
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

const HeaderContainerOuter = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-left: 2%;
  width: 80%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const HeaderContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  @media (max-width: 1250px) {
    width: 90%;
  }
`;

const SliderContainer = styled(motion.div)`
  width: 100%;
  cursor: grab;
  margin-left: 10%;
  margin-top: -50px;
  @media (max-width: 768px) {
    /*margin-left: 5%;*/
    margin: 0 !important;
  }
  .swiper-wrappe {
    align-items: center;
  }
`;

const HeaderTextContent = styled(motion.div)``;

export class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <ImageGalleryContainer backgroundColor={this.props.backgraund_color}>
            <HeaderContainerOuter>
              <HeaderContainer>
                <HeaderTextContent>
                  <BlockTitle title_color={this.props.title_color}>
                    {this.props.name_of_the_gallery.text}
                  </BlockTitle>
                </HeaderTextContent>
              </HeaderContainer>
            </HeaderContainerOuter>
            <SliderContainer>
              <SimpleCarousel
                items={this.props.items}
                visible={this.state.visible}
              />
            </SliderContainer>
          </ImageGalleryContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default ImageGallery;
